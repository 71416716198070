<template>
    <div>
        <vs-table 
            search 
            stripe 
            border 
            description 
            :sst="true" 
            :data="table.data" 
            :max-items="table.length"
            :total="table.total" 
            @search="handleSearch" 
            @change-page="handleChangePage" 
            @sort="handleSort"
        >
            <template slot="header">
                <vs-dropdown vs-trigger-click class="cursor-pointer">
                    <div class="p-3 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
                        <span class="mr-2">{{ this.table.start }} - {{ this.table.end }} of {{ this.table.total }}</span>
                        <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
                    </div>
                    <vs-dropdown-menu>
                        <vs-dropdown-item v-for="item in table.limits" :key="item" @click="handleChangelength(item)">
                            <span>{{ item }}</span>
                        </vs-dropdown-item>
                    </vs-dropdown-menu>
                </vs-dropdown>
            </template>

            <template slot="thead">
                <vs-th>Action</vs-th>
                <vs-th sort-key="">Document Details</vs-th>
                <vs-th sort-key="">Type</vs-th>
                <vs-th sort-key="">Transaction Date</vs-th>
                <vs-th sort-key="">Source Warehouse Area</vs-th>
                <vs-th sort-key="">Destination Warehouse Area </vs-th>
                <vs-th sort-key=""># of SKU </vs-th>
                <vs-th sort-key="">Total UOM </vs-th>
            </template>

            <template slot-scope="{ data }">
                <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                    <vs-td>
                        <div class="vx-input-group flex" v-if="status == 0 || status == 7">
                            <vs-button
                                size="small"
                                color="warning"
                                icon-pack="feather"
                                icon="icon-edit"
                                title="Edit"
                                style="margin-right: 5px"
                                @click="handleEdit(tr.id)"
                            />
                            <vs-button
                                size="small"
                                color="danger"
                                icon-pack="feather"
                                icon="icon-x"
                                title="Cancel"
                                style="margin-right: 5px"
                                @click="handleCancel(tr.id)"
                            />
                            <vs-button
                                size="small"
                                color="success"
                                icon-pack="feather"
                                icon="icon-check"
                                title="Request Approval"
                                style="margin-right: 5px"
                                @click="handleApprove(tr.id)"
                            />
                        </div>
                        <div class="vx-input-group flex" v-if="status == 4">
                            <vs-button
                                size="small"
                                color="warning"
                                icon-pack="feather"
                                icon="icon-eye"
                                title="Detail"
                                style="margin-right: 5px"
                                @click="handleShow(tr.id)"
                            />
                            <vs-button
                                size="small"
                                color="primary"
                                icon-pack="feather"
                                icon="icon-printer"
                                title="Print"
                                style="margin-right: 5px"
                                @click="handlePrint(tr.id)"
                            />
                            <vs-button
                                size="small"
                                color="success"
                                icon-pack="feather"
                                icon="icon-check"
                                title="Execute"
                                style="margin-right: 5px"
                                @click="handleApprove(tr.id)"
                            />
                            <vs-button
                                size="small"
                                color="danger"
                                icon-pack="feather"
                                icon="icon-x"
                                title="Cancel"
                                style="margin-right: 5px"
                                @click="handleCancel(tr.id)"
                            />
                        </div>
                        <div class="vx-input-group flex" v-if="status != 0 && status != 4 && status != 7">
                            <vs-button
                                size="small"
                                color="warning"
                                icon-pack="feather"
                                icon="icon-eye"
                                v-if="status !== 0 && status !== 7"
                                title="Show"
                                style="margin-right: 5px"
                                @click="handleShow(tr.id)"
                            />
                            <vs-button
                                size="small"
                                color="primary"
                                icon-pack="feather"
                                icon="icon-printer"
                                v-if="status === 5"
                                title="Print"
                                style="margin-right: 5px"
                                @click="handlePrint(tr.id)"
                            />
                        </div>
                    </vs-td>
                    <vs-td>{{ tr.code }}</vs-td>
                    <vs-td>{{ tr.storage_type }}</vs-td>
                    <vs-td>{{ tr.date }}</vs-td>
                    <vs-td>Code : {{ tr.src_warehouse_area_code }} <br> Name : {{ tr.src_warehouse_area_name }}</vs-td>
                    <vs-td>Code : {{ tr.dest_warehouse_area_code }} <br> Name : {{ tr.dest_warehouse_area_name }}</vs-td>
                    <vs-td>{{ tr.total_sku }}</vs-td>
                    <vs-td>{{ tr.quantity_uom }}</vs-td>
                </vs-tr>
            </template>
        </vs-table>
        <vs-pagination style="padding-top: 5px" :total="table.totalPage" v-model="setPage" />
    </div>
</template>

<script>
import moment from "moment";
export default {
    props: {
        draw: {
            type: Number,
        },
        baseUrl: {
            type: String,
        },
        title: {
            type: String,
        },
        action: {
            type: String,
        },
        status: {
            type: Number,
        },
        warehouse: {
            type: Array,
        },
        date: {
            type: String,
        },
        isFilter: {
            type: Boolean,
        },
    },
    data() {
        return {
            id: 0,
            table: this.tableDefaultState(),
        };
    },
    methods: {
        tableDefaultState() {
            return {
                data: [],
                length: 10,
                page: 1,
                search: "",
                order: "",
                sort: "",
                total: 0,
                totalPage: 1,
                totalSearch: 0,
                limits: [10, 25, 50, 100, "All"],
                start: 1,
                end: 0,
            };
        },
        handleSearch(searching) {
            this.table.search = searching;
            this.table.page = 1;
            this.getData();
        },
        handleChangePage(page) {
            this.table.page = page;
            this.getData();
        },
        handleSort(key, active) {
            this.table.order = key;
            this.table.sort = active;
            this.getData();
        },
        handleChangelength(val) {
            this.table.length = val == "All" ? this.table.total : val;
            this.table.page = 1;
            this.getData();
        },
        setStartEnd() {
            let valStart = this.table.length * this.table.page - this.table.length + 1;

            if (valStart > this.table.total) {
                valStart = 1;
            }

            if (this.table.total == 0) {
                valStart = 0;
            }

            let valEnd = this.table.length * this.table.page;

            if (valEnd > this.table.total) {
                valEnd = this.table.total;
            }

            if (this.table.totalSearch < this.table.total && this.table.search != "") {
                valEnd = this.table.totalSearch;
            }

            this.table.start = valStart;
            this.table.end = valEnd;
        },
        getData() {
            this.$vs.loading();

            let warehouseIDs = [];
            this.warehouse.forEach(val => {
                warehouseIDs.push(val.id)
            });

            let param = {
                length: this.table.length,
                page: this.table.page,
                search: this.table.search,
                order: this.table.order,
                sort: this.table.sort,
                status: String(this.status),
                date: this.date ? this.date : null,
                warehouse_id: warehouseIDs,
            }

            this.$http.post(this.baseUrl + "/list", param)
            .then((resp) => {
                if (resp.code == 200) {
                    this.table.total = resp.data.total_record;
                    this.table.totalPage = resp.data.total_page;
                    this.table.totalSearch = resp.data.total_record_search;
                    this.table.length = resp.data.total_record_per_page;
                    this.table.data = resp.data.records;
                    this.$emit("isFilter", false);
                    this.setStartEnd();
                    this.$vs.loading.close();
                } else {
                    this.$vs.loading.close();
                }
            })
            .catch((error) => {
                this.$vs.loading.close();
                console.log(error);
            });
        },
        handleEdit(id) {
            this.$router.push({
                name: "inter-storage-edit",
                params: { id: id },
            });
        },
        handleShow(id) {
            this.$router.push({
                name: "inter-storage-show",
                params: { id: id, status: this.status },
            });
        },
        handlePrint(id) {
            const url = this.$router.resolve({
                name: "operation.inter-storage.print",
                params: { id: id },
            }).href;
            window.open(url, "_blank")
        },
        handleApprove(id) {
            this.$vs.dialog({
                type: "confirm",
                color: "danger",
                title: `Submit`,
                text: `Are you sure to ${this.status == 0 || this.status == 7 ? "send approval for" : "execute"} this data?`,
                accept: () => {
                    this.$vs.loading();
                    let st = null;
                    if (this.status == 0 || this.status == 7) {
                        st = 3;
                    } else if (this.status == 4) {
                        st = 5;
                    }
                    this.$http.post("api/wms/v1/inter-storage/update-status", {
                        id: id,
                        status: st,
                        tab_source: String(this.status)
                    })
                    .then((resp) => {
                        this.$vs.loading.close();
                        if (resp.code == 200) {
                            this.$vs.dialog({
                                color: "success",
                                title: "Success",
                                text: resp.message,
                            });
                            this.getData();
                        } else {
                            this.$vs.dialog({
                                color: "danger",
                                title: "Something went wrong",
                                text: resp.message,
                            });
                        }
                    })
                    .catch((error) => {
                        this.$vs.loading.close();
                        this.$vs.dialog({
                            color: "danger",
                            title: "Something went wrong",
                            text: error,
                        });
                        console.log(error);
                    });
                }
            })
        },
        handleCancel(id) {
            var tabStatus = ""
            if (this.status == 0) {
                tabStatus = "Draft"
            }
            this.$vs.dialog({
                type: "confirm",
                color: "danger",
                title: `Submit`,
                text: "Are you sure to cancel this data?",
                accept: () => {
                    this.$vs.loading();
                    this.$http.post("api/wms/v1/inter-storage/update-status", {
                        id: id,
                        status: 9,
                        tab_status: tabStatus,
                        tab_source: String(this.status)
                    })
                    .then((resp) => {
                        this.$vs.loading.close();
                        if (resp.code == 200) {
                            this.$vs.dialog({
                                color: "success",
                                title: "Success",
                                text: "The data was successfully canceled",
                            });
                            this.getData();
                        } else {
                            this.$vs.dialog({
                                color: "danger",
                                title: "Something went wrong",
                                text: resp.message,
                            });
                        }
                    })
                    .catch((error) => {
                        this.$vs.loading.close();
                        this.$vs.dialog({
                            color: "danger",
                            title: "Something went wrong",
                            text: error,
                        });
                        console.log(error);
                    });
                }
            })
        },
        handleMaintenance(id) {
            this.$http.post("api/wms/v1/inter-storage/sehat-menyegarkan", {
                id: id,
            })
            .then(resp => {
                if (resp.code == 200) {
                    this.$vs.notify({
                        color: "success",
                        title: "Success",
                        text: resp.message,
                        position: "top-right",
                        iconPack: "feather",
                        icon: "icon-x-circle",
                    });
                } else {
                    this.$vs.dialog({
                        type: "alert",
                        acceptText: `OK`,
                        color: "danger",
                        title: `Error`,
                        text: resp.message,
                    });
                }
            })
            .catch((error) => {
                this.$vs.loading.close();
                console.log(error);
            });
        },
    },
    mounted() {
        // ..
    },
    computed: {
        setPage: {
            get() {
                return 1;
            },
            set(val) {
                this.handleChangePage(val);
            },
        },
    },
    filters: {
        formatDate(value) {
            if (value) {
                return moment(value).add(-7, "h").utc().local().format("DD MMM YYYY");
            }
        },
    },
    watch: {
        draw() {
            this.getData();
        },
    },
};
</script>
